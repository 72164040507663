import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import Img from 'gatsby-image'
import { removeTrailingSlash } from '../../utils'
import { Link } from 'gatsby'
import { useDefaultArchiveImage } from '../../hooks'

import './BlogSummary.sass'

const BlogSummary = ({ slug, title, featuredImage, date, excerpt }) => {
  const defaultImg = useDefaultArchiveImage()
  const img = !!featuredImage ? featuredImage : defaultImg
  return (
    <section className={`article-summary grid`}>
      <Link
        className="article-summary-img-link col-12_tp-3"
        to={removeTrailingSlash(slug)}
        rel="bookmark"
      >
        <Img
          className="article-summary-img"
          fluid={img.src.childImageSharp.fluid}
          alt={img.alt}
        />
      </Link>
      <div className={`article-summary-content col-12_tp-9`}>
        <Link to={removeTrailingSlash(slug)} className="article-summary-link">
          <h3 className="article-summary-title">{title}</h3>
        </Link>

        <span className="article-summary-meta">
          <time
            className="posted entry-time"
            dateTime={date.format('YYYY-MM-DD')}
          >
            {date.format('MMM D, YYYY')}
          </time>
        </span>
        <p className="article-summary-excerpt">
          {excerpt}{' '}
          <Link to={slug} className="article-summary-link">
            Read More &raquo;
          </Link>
        </p>
      </div>
    </section>
  )
}

export const blogSummaryProps = {
  slug: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  featuredImage: PropTypes.shape({
    src: PropTypes.object.isRequired,
    alt: PropTypes.string.isRequired,
  }),
  date: PropTypes.instanceOf(moment().constructor).isRequired,
  excerpt: PropTypes.string.isRequired,
}

BlogSummary.propTypes = blogSummaryProps

export default BlogSummary
