import React, { Fragment } from 'react'
import { v4 as uuidv4 } from 'uuid'
import PropTypes from 'prop-types'
import HTMLContent from '../HTMLContent'
import { useRecentBlogArticles } from '../../hooks'
import moment from 'moment'
import BlogSummary from '../Archive/BlogSummary'

const BlogRollPanel = ({ content_MD, inlineImages, maxPosts }) => {
  const posts = useRecentBlogArticles()
  return (
    <Fragment>
      {!!content_MD && (
        <HTMLContent
          content={content_MD}
          className="panel-text"
          inlineImages={inlineImages}
        />
      )}
      {posts
        .slice(0, maxPosts || 5)
        .map(
          ({
            node: {
              fields: { slug },
              excerpt,
              frontmatter: { title, date, featuredImage },
            },
          }) => (
            <BlogSummary
              key={uuidv4()}
              slug={slug}
              title={title}
              featuredImage={featuredImage}
              date={moment(date, 'MMM DD, YYYY')}
              excerpt={excerpt}
            />
          ),
        )}
    </Fragment>
  )
}

BlogRollPanel.propTypes = {
  content_MD: PropTypes.string,
  inlineImages: PropTypes.array,
  maxPosts: PropTypes.number,
}

export default BlogRollPanel
